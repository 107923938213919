<template>
    <div class="task-box">
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>任务中心</el-breadcrumb-item>
            <el-breadcrumb-item>服务项目</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="editRule">
                    <el-button @click="add" type="primary" size="small" 
             icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item >
                    显示未启用
                    <el-switch
                        @change="getProject"
                        v-model="status">
                    </el-switch>
                </el-form-item>
            </el-form>
        </div>
        <!-- content -->
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="projectList"
            style="width: 100%">
                <el-table-column
                    prop="time"
                    label="时间">
                    <template slot-scope="scope">
                        {{scope.row.time|dateFormat}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="名称">
                    <template slot-scope="scope">
                        {{scope.row.name}}
                        <i class="el-icon-s-help" title="自定义表单" @click="toForm" v-if="scope.row.content" style="color:#39f"/>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注">
                </el-table-column>
                <el-table-column
                    label="状态">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <el-button size="mini" plain :type="scope.row.status==0?'primary':'info'">
                                {{scope.row.status==0?'启用':'未启用'}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="item in [0,1]" :key="item" @click.native="changeSatus(scope.row.pid,item)">
                                {{item==0?'启用':'未启用'}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                width="100"
                fixed="right"
                v-if="editRule"
                label="操作">
                    <template slot-scope="scope">
                        <i class="edit el-icon-delete-solid" @click="handleDelete(scope.row.pid)"></i>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- addTag-->
        <el-drawer
          title="添加项目"
          :visible.sync="drawer"
          direction="rtl">
            <AddTag @onSubmit="onSubmit" types="project"/>
        </el-drawer>
        <!-- page -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :page-count="totalPage">
            </el-pagination>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
import AddTag from '@/components/common/AddTag'
export default {
    components:{
        AddTag
    },
    data(){
        return{
            form:{},
            page:1,
            totalPage:1,
            pageSize:10,
            editRule:checkRules(15),//项目管理权限
            drawer:false,
            loading:false,
            projectList:[],
            status:false,//0为启用的，1为未启用的
        }
    },
    created(){
        this.getProject()
    },
    methods:{
        //选择页码
        handleCurrentChange(e){
            this.page=e 
            this.getProject()
        },
        //改变每页显示数量
        handleSizeChange(){
            this.getProject()
        },
        //调转自定义表单
        toForm(){
            let url = "../Form/list"
            window.open(url)
        },
        onSubmit(form){
            if(!form.name||form.name==''){
                this.$message({
                    message: this.CONST.FAIL_LOGIN_PARAMS,
                    type: 'warning'
                });
                return
            }
            form.method = "addProject"
            this.$ajax.post(this.API.api,form).then(res=>{
                //todo
                //console.log(res)
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.loading = false 
                this.drawer = false
                this.getProject()
            }).catch(err=>{
                //err
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        add(){
            this.drawer = true
        },
        handleDelete(pid){
            this.$confirm(this.CONST.DEL_CONFIRM)
                .then(res=> {
                    console.log(res)
                    let params = {
                        method:'delProject',
                        pid:pid
                    }
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //todo
                        this.loading = false 
                        this.$message({
                            message: res.msg?res.msg:this.CONST.DEL_SUC,
                            type: 'success'
                        });
                        this.getProject()
                    }).catch(err=>{
                        //err
                        this.loading = false 
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })
                }).catch(err=>{
                    console.log(err)
                })
            
        },
        /**
         * 改变可用状态
         */
        changeSatus(pid,status){
            if(!this.editRule){
                this.$message({
                    message: this.CONST.NO_RULES,
                    type: 'warning'
                });
                return
            }
            let params = {
                method:'changeProjectStatus',
                pid:pid,
                status:status
            }
            this.loading  = true 
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res)
                this.$message({
                    message: res.msg,
                    type: 'success'
                });
                this.loading = false 
                this.getProject()
            }).catch(err=>{
                //err
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        getProject(){
            let params = {
                method:"getProject",
                status:this.status?1:0,
                page:this.page,
                pageSize:this.pageSize
            }
            //console.log(params,this.status)
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                //console.log(res.data)
                this.loading = false
                this.projectList = res.data
                if(this.projectList[0]&&this.projectList[0].totalPage){
                    this.totalPage = this.projectList[0].totalPage
                }
            }).catch(err=>{
                //err
                console.log(err.data)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
.pro-con{
    margin:10px 0;
}
.task-box{
    .header-bar{
        margin: 10px 0;
    }
    .el-icon-s-help{
        cursor:pointer;
    }
}
</style>